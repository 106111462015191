import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  openMenu = true;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.getSpecialities()
  }
}

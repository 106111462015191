import { Component, OnInit, Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss'],
    standalone: false
})
export class ReviewComponent implements OnInit {

  @Input() rating: number;
  @Input() itemId: number;

  inputName: string;
  dateId = "";
  review = "";

  
  onClick(rating: number): void {
    this.rating = rating;
    console.log(rating)
  }

  constructor( private service: AuthService, private router: Router, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ReviewComponent> ) {   }

  ngOnInit() {
    this.dateId = (this.data.id)
    this.inputName = this.itemId + '_rating';
  }

  send(){
    let data = {
      message: this.review,
      score: this.rating
    };

    this.service.sendReview(this.dateId,data).subscribe( d =>{
      this.service.datesFinish(this.dateId).subscribe(d =>{
        console.log(d)
        this.router.navigate(['/panel/booking']);
        this.dialogRef.close();
      });
    });
    
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
    selector: 'app-re-password',
    templateUrl: './re-password.component.html',
    styleUrls: ['./re-password.component.scss'],
    standalone: false
})
export class RePasswordComponent implements OnInit {

  email = "";
  newpass = "";
  rePassSucc = false;
  loading = false;
  tokenre = "";

  constructor(private service: AuthService, private route:Router) { }

  ngOnInit() {
  }

  rePass() {
    let data = {
      email: this.email
    }
    this.loading = true;

    this.service.rePassRequest(data).subscribe((d: any) => {
      this.rePassSucc = true;
      this.loading = false;
      this.tokenre = d.data.items[0].dev_only_token;
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.error.errors[0].message,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    })
  }

  newPass() {

    let data = {
      password: this.newpass,
      token: this.tokenre
    }

    console.log(data)

    this.service.rePassword(data).subscribe(d => {
      console.log(d);
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: "Su Contraseña ha sido cambiada",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.route.navigate(['landing']);

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.error.errors[0].message,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    });
  }

}

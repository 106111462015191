import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ConektaService } from 'src/app/services/conekta.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
declare var Conekta:any;

@Component({
    selector: 'app-payment-booking',
    templateUrl: './payment-booking.component.html',
    styleUrls: ['./payment-booking.component.scss'],
    standalone: false
})
export class PaymentBookingComponent implements OnInit {

  @Output() dataEvent = new EventEmitter<any>()
  @Input() datainput

  userSub: Subscription = new Subscription();
  userId = 0;
  
  cardToken = "";

  savedCards = [];

  publicKey = "key_aX1B6yrp1V1cmkkr7rY47ng";
  selectedCardIndex: number = -1; 
 
  constructor( 
    private conektaService: ConektaService, 
    public dialogRef: MatDialogRef<PaymentBookingComponent>,   
     @Inject(MAT_DIALOG_DATA) public data, 
    private store: Store<AppState>,
    private service: AuthService ) { }

  ngOnInit() {

    this.publicKey = this.service.conektaApiKey;
    this.userSub = this.store.select('user').subscribe( d=>{
      this.userId = d.data.id;
      this.getCards();
    })

    Conekta.setPublicKey(this.publicKey);
    Conekta.setLanguage("es");
  }

  ngOnDestroy(){
    this.userSub.unsubscribe();
  }

  getCards(){

    this.service.getUserCards().subscribe( (d:any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"private"));

      console.log('CARDS',decData.data.items);
      this.savedCards = decData.data.items;
    },err=>{
      let decData = JSON.parse(this.service.decrypt(err.error.message,"private"));
      console.log(decData)
    }) 

  }

  selectCard(i){
    this.selectedCardIndex = i;
    var sections = document.querySelectorAll('.cardss');
        for (let i = 0; i < sections.length; i++){
          sections[i].classList.remove('active-categorie');
        }
    document.querySelector(`#card${i}`).classList.add('active-categorie');
    console.log('hola')
  }

  pay(i){
    this.data = this.savedCards[i];
    console.log(this.data)
    this.dialogRef.close(this.data);
  }

  closeModal(){
    this.dialogRef.close(this.data);
  }

  addCard(){
    this.dataEvent.emit(1)
  }

  refresh(){
    this.savedCards = [];
    this.getCards()
  }
}

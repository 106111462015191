import { Component, OnInit,Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-close',
    templateUrl: './close.component.html',
    styleUrls: ['./close.component.scss'],
    standalone: false
})
export class CloseComponent implements OnInit {

  /*@Input() rating: number;
  @Input() itemId: number;*/

  //inputName: string;
  dateId = "";
  userId = "";
  //review = "";

  /*onClick(rating: number): void {
    this.rating = rating;
    console.log(rating)
  }*/

  constructor(private service: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CloseComponent> ) { }

  ngOnInit() {
    this.dateId = (this.data.id)
    this.userId = (this.data.userId)
    //this.inputName = this.itemId + '_rating';
  }

  send(){
    /*let data = {
      message: this.review,
      score: this.rating
    };*/

    //this.service.sendReview(this.dateId,data).subscribe( d =>{
      this.service.inquiriesFinish(this.dateId,this.userId).subscribe(d =>{
        console.log(d)
        this.router.navigate(['/']);
        this.dialogRef.close();
      });
    //});

  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit {

  public registerFG: FormGroup;


  constructor( private service: AuthService,private _formBuilder: FormBuilder ) { }

  ngOnInit() {

    this.registerFG = this._formBuilder.group({
      password: ['', Validators.required],
      repassword: ['', Validators.required],
      
    });
  }

  saveForm() {

      let user = {
        "old_password": this.registerFG.value.password,
        "new_password": this.registerFG.value.repassword,
      }
  
      console.log(user)

      let encUser = {data: this.service.encrypt(user,"private")}
  
      // this.service.updatePassword(encUser).subscribe(data => {
      //   console.log(data)
      //   Swal.fire({
      //     icon: 'success',
      //     title: 'Listo',
      //     text: "Tu contraseña ha sido modificada",
      //   });
      // },err =>{
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Ha ocurrido un error',
      //     text: err,
      //   });
      // })

  }

}

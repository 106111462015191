import { Component, OnInit, AfterViewChecked, AfterContentChecked, LOCALE_ID } from '@angular/core';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import config from '../services/config';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReBokingComponent } from '../new-booking/re-boking/re-boking.component';
import {  MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';


@Component({
    selector: 'app-bookin',
    templateUrl: './bookin.component.html',
    styleUrls: ['./bookin.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'es' }, { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
    standalone: false
})

export class BookinComponent implements OnInit, AfterContentChecked {

  bookings = [];
  bookingsUpcoming = [];
  bookingsToBeCancelled = [];
  bookingsPast = [];
  today = new Date();
  time_zone = "";
  maxRangeDate = new Date().getTime() + (24 * 60 * 60 * 1000);
  index = 0;
  showGetMore = true;
  isLoading = false;

  constructor(private service: AuthService, private router: Router, public dialog: MatDialog , private route: ActivatedRoute) { }

  ngOnInit() {
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.service.user.time_zone ? this.service.user.time_zone : resolvedOptions.timeZone;
    this.refresh();
    this.comprobarId();

    //this.getBookings();
    //this.getBookingsUpcoming();
    //this.getBookingsPast();
  }

  comprobarId(){
    this.route.queryParams.subscribe(params => {
      const idParam = params['id'];
      if (idParam) {
        this.service.confirmAppointment(idParam).subscribe((d: any) => {          
          Swal.fire({
            icon: 'success',
            title: '¡Tu sesión ha sido agendada!',
            text: "Muy pronto recibirás confirmación de tu terapeuta",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          })
        }, err => { 
          console.log(err) 
          Swal.fire({
            icon: 'error',
            title: 'Ocurrió un error durante el proceso de pago',
            text: "Intenta de nuevo más tarde, o checa con tu banco para más información.",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
        });
      }
    });
  }

  ngAfterContentChecked(){
    // this.bookings.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookings[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })

    // this.bookingsUpcoming.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsUpcoming[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })

    // this.bookingsPast.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsPast[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })
  }

  changeTab($event){
    this.index = $event.index;
    switch(this.index){
      case 0: if(this.bookings.length == 0)this.getBookings(); break;
      case 1: if(this.bookingsUpcoming.length == 0)this.getBookingsUpcoming();break;
      case 2: if(this.bookingsPast.length == 0)this.getBookingsPast();break;
    }
  }

  getBookings() {
    //let maxDate = new Date().getTime() + (24 * 60 * 60 * 1000);
    let maxxDate = new Date()
    let minnDate = new Date()
    maxxDate.setDate(maxxDate.getDate() + 1)
    minnDate.setDate(minnDate.getDate() - 1)
    maxxDate.setHours(0,0,0,0)
    minnDate.setHours(24,60,60,0)
    let maxDate = maxxDate.getTime()
    let minDate = minnDate.getTime()
    //console.log('MAXDATE',maxDate)

    this.service.datesScheduled(this.service.user.id).subscribe((d: any) => {
      //GET DATES IN PROXIMITY
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      //console.log('ITEMS DATES SCHEDULLED:',decData.data.items)
      let bookings = decData.data.items.filter(elem => elem.start_at < maxDate && elem.start_at > minDate);

      this.bookings = bookings.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      })

      let upcoming = decData.data.items.filter(elem => elem.start_at > maxDate && elem.start_at > minDate);
      let bookingsUpcoming = upcoming.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });

      this.bookingsUpcoming = [...this.bookingsPast, ...bookingsUpcoming];

      //GET DATES TO BE CONFIRMED
      this.service.datesToBeConfirmed(this.service.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.service.decrypt(d.message, 'private'))
        //('TO BE CONFIRMED:', decData.data.items)
        let bookingsToBeConfirmed = decData.data.items.map(elem => {
          return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
        })
        this.bookingsUpcoming = [...this.bookingsPast, ...bookingsUpcoming, ...bookingsToBeConfirmed];
      })

      //GET DATES FROM TODAY
      this.service.datesInProgress(this.service.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.service.decrypt(d.message, "private"));
        //console.log('IN PROGRESS:',decData.data.items)
        let bookings = decData.data.items.map(elem => {
          return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
        })
        this.bookings = [...bookings, ...this.bookings];
      }, err => { 
        if(err.name === "HttpErrorResponse"){
          Swal.fire({
            title: 'Error de conexión',
            text: 'Intente de nuevo más tarde.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
        }
        console.log(err) });

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.msg,
      });
    });
  }

  getBookingsUpcoming() {
    this.service.datesToConfirm(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      //console.log(decData.data.items)

      let bookingsUpcoming = decData.data.items.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });

      //this.bookingsUpcoming = [...this.bookingsUpcoming, ...bookingsUpcoming];
      this.bookingsUpcoming = bookingsUpcoming;
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.msg,
      });
    });

    this.service.datesToBeCancelled(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      //console.log(decData.data.items)

      let bookingsUpcoming = decData.data.items.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });

      //this.bookingsUpcoming = [...this.bookingsUpcoming, ...bookingsUpcoming];
      this.bookingsToBeCancelled = bookingsUpcoming;
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(this.service.decrypt(err.error.message,'private'))
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.msg,
      });
    });
  }

  getBookingsPast() {
    let data = {
      time_zone: 'America/Regina'
    }
    this.service.endDatesFinish(10, this.bookingsPast.length).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      //console.log(decData.data.items)
      //this.bookingsPast = decData.data.items;

      if(decData.data.items.length == 0) this.showGetMore = false;
      decData.data.items.forEach(item => {
        this.bookingsPast.push(item)
      });
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.msg,
      });
    });

    this.service.datesCancelled(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      //console.log(decData.data.items)
      //this.bookingsPast = decData.data.items;

      if(decData.data.items.length == 0) this.showGetMore = false;
      decData.data.items.forEach(item => {
        this.bookingsPast.push(item)
      });
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.msg,
      });
    });

  }

  cheackDates(date: string = "") {
    let tz = momentTZ.tz(date, this.time_zone).clone().tz(this.time_zone).format();
    let tzDay = momentTZ.tz(this.today, this.time_zone).format();

    return !((Date.parse(tz) - 300000) <= Date.parse(tzDay));
  }

  checkReDate(date){
    return (date < this.maxRangeDate);
  }

  goSession(b) {

    this.service.conectDate(b.patient_user_id.id, b.id).subscribe((d: any) => {//doctor_user_id.id
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      //console.log(decData.data.items[0])
      config.SESSION_ID = decData.data.items[0].conference_session_token;
      config.TOKEN = decData.data.items[0].token;
      this.router.navigate(['/panel/video', b.id]);
    })
  }

  goChat(booking) {
    this.router.navigate(['/panel/chat']);
  }

  returnStatus(s) {
    let status = s;
    switch (s) {
      case 'scheduled_appointment':
        status = "Cita agendada";
        break;
      case 'appointment_in_progress':
        status = "En progreso"
        break;
      case 'to_be_confirmed_by_therapist':
        status = "Por confirmar"
        break;
      case 'to_be_cancelled_by_therapist':
        status = "Por confirmar cancelación";
        break;
      case 'appointment_cancelled':
        status = 'Cita cancelada'
        break;
      case 'appointment_finished':
        status = 'Sesión finalizada';
        break;
      default:
        status = s;
        break;
    }

    return status

  }

  refresh(){
    switch(this.index){
      case 0:this.getBookings(); break;
      case 1:this.getBookingsUpcoming(); break;
      case 2:this.showGetMore = true; this.bookingsPast = []; this.getBookingsPast(); break;
    }
    /*this.getBookings();
    this.getBookingsUpcoming();
    this.getBookingsPast();*/
  }

  reDateBooking(doc){
    //console.log(doc)
    const dialogRef = this.dialog.open(ReBokingComponent, {
      width: '800px',
      height: '600px',
      data: doc
    });
    this.refresh()
  }

  setDefaultPic(event){
    event.target.src = './assets/icono.png';
  }

  showCancel(appointment, mode = 1){
    //console.log(appointment)
    if(mode === 1){
      Swal.fire({
        title: 'Cancelar',
        text: 'Puedes cancelar hasta 24 horas antes de tu cita',
        icon: 'error',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonColor:'#023171',
        preConfirm:() => {
          this.cancelBooking(appointment);
        }
      })
    } else {
      Swal.fire({
        title: 'Cancelar',
        text: 'Se cancelará la solicitud de videollamada que se hizo al terapeuta ' + appointment.doctor.first_name + ' ' + appointment.doctor.last_name + ', ¿Está seguro de que desea continuar?',
        icon: 'error',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonColor:'#023171',
        preConfirm:() => {
          this.cancelBooking(appointment);
        }
      })
    }
  }

  cancelBooking(appointment){
    let body = {
      status:"to_be_cancelled_by_therapist",
    }
    //console.log(appointment)
    this.service.cancelBooking(body, appointment).subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      if(appointment.promo_code === null){
        Swal.fire({
          icon: 'success',
          title: 'Su cita ha sido cancelada.',
          text: "Su terapeuta será notificado.\nVerá su reembolso reflejado en un máximo de 72 horas.",
          showConfirmButton: true,
          confirmButtonText: "ok",
          confirmButtonColor:'#023171',
        });
        this.refresh()
        return
      }
      Swal.fire({
        icon: 'success',
        title: 'Su cita ha sido cancelada.',
        text: "Su terapeuta será notificado.",
        showConfirmButton: true,
        confirmButtonText: "ok",
        confirmButtonColor:'#023171',
      });
      this.refresh()
    },err=>{
      let decData = JSON.parse(this.service.decrypt(err.error.message, "private"));
      console.log(decData)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.refresh()
    });
  }

}

<div>
    <img src="../../../assets/img/logo_prisma.png" class="image"/>
</div>

<div class="body">
    <h3 class="title">Términos y Condiciones</h3>
    <p class="text">
        ESTOS TÉRMINOS Y CONDICIONES (EN ADELANTE, LOS "TÉRMINOS Y CONDICIONES") TIENEN POR OBJETO REGULAR EL USO QUE REALICEN LAS PERSONAS QUE INGRESEN AL SITIO https://asistenciapsicologicagnp.com (EN ADELANTE, EL "SITIO WEB"). <br/>
        AL INGRESAR AL SITIO WEB, EL USUARIO DECLARA SER MAYOR DE 18 AÑOS, POSEER CAPACIDAD LEGAL PARA CONTRATAR Y NO TENER NINGÚN IMPEDIMENTO SEGÚN LAS LEYES DE SU PAÍS PARA UTILIZAR EL SITIO WEB. <br/>
        LOS TÉRMINOS Y CONDICIONES PODRÁN SER PERIÓDICAMENTE MODIFICADOS Y ACTUALIZADOS POR MEDICA MOVIL, SIN OBLIGACIÓN DE NOTIFICAR PARTICULARMENTE AL USUARIO. <br/>
        EL SOLO INGRESO AL SITIO WEB, POR PARTE DE UN USUARIO IMPLICA LA ACEPTACIÓN COMPLETA DE LOS TÉRMINOS Y CONDICIONES QUE SE DETALLAN A CONTINUACIÓN. <br/><br/>
        <strong> (LEER CON ATENCIÓN): </strong><br/>
        NO UTILICE EL SITIO WEB PARA NECESIDADES MÉDICAS DE EMERGENCIA. SI SU SALUD O INTEGRIDAD ESTÁN EN GRAVE PELIGRO, LLAME A AL NÚMERO DE EMERGENCIAS DEL LUGAR DE SU RESIDENCIA INMEDIATAMENTE. <br/>
        SI ESTÁ PENSANDO EN EL SUICIDIO O SI ESTÁ CONSIDERANDO LLEVAR A CABO ACCIONES QUE PUEDAN PONERLO EN PELIGRO A USTED O A TERCEROS, DEBE LLAMAR AL NÚMERO DE EMERGENCIAS DEL LUGAR DE SU RESIDENCIA INMEDIATAMENTE. <br/>
        <strong>Nosotros.</strong> <br/>
        Médica Móvil es la única titular y dueña del Sitio Web. <br/><br/>
    <strong>1. Contenidos del Sitio Web.</strong> <br/>
        Los consejos, artículos, blogs, videos y toda otra información o contenido que surge del Sitio Web (en adelante, el "Contenido") no deben considerarse servicios o tratamientos médicos bajo ninguna perspectiva. Los Contenidos se ofrecen para ayudar a mejorar el bienestar del Usuario si no está en situación de urgencia. Bajo ningún concepto debe entenderse que el Contenido constituye un reemplazo del tratamiento psicológico profesional, y ante cualquier circunstancia el Usuario deberá siempre consultar a un profesional de la salud. Ningún Contenido representa o garantiza que ningún medicamento o tratamiento en particular sea seguro, apropiado o efectivo para usted. <br/><br/>
    <strong>2. Usos Permitidos del Sitio Web.</strong> <br/>
        El uso del Sitio Web es exclusiva responsabilidad del usuario, quien en todo caso deberá servirse del mismo acorde a las funcionalidades permitidas en el propio sitio y a los usos autorizados en los presentes Términos y Condiciones, por lo que el usuario se obliga a utilizarlos de modo tal que no atenten contra las normas de uso y convivencia en Internet, las leyes aplicables en el país en que el usuario se encuentre al usarlos, las buenas costumbres y los derechos de terceros. Los usuarios se comprometen a utilizar los Contenidos del Sitio Web exclusivamente para su uso personal, y a no realizar ni directa ni indirectamente una explotación comercial de los mismos. Los usuarios no tienen el derecho de colocar hipervínculos de y hacia el Sitio Web, ni el derecho de colocar o utilizar los contenidos del mismo en sitios o páginas propias o de terceros sin autorización previa y por escrito de MEDICA MOVIL. Asimismo, los usuarios no tendrán el derecho de limitar o impedir a cualquier otro usuario el uso del Sitio Web. <br/><br/>
    <strong>3. Política de Privacidad.</strong> <br/>
        MEDICA MOVIL procesará los datos personales que el usuario proporcione o facilite a través del Sitio Web de conformidad con la política de privacidad. <br/><br/>
    <strong>4. Cuenta del usuario. </strong><br/>
        Todas las Cuentas de los usuarios permanecen bajo propiedad de MEDICA MOVIL, y pueden ser canceladas o suspendidas en cualquier momento sin previo aviso. <br/>
        La confidencialidad de los datos de la cuenta y contraseña del Usuario son de su exclusiva responsabilidad. Asimismo, todas las actividades realizadas en el Sitio Web bajo su nombre de usuario y contraseña son de su exclusiva responsabilidad. <br/><br/>
    <strong>5. Propiedad intelectual e industrial. </strong><br/>
        MEDICA MOVIL es titular de todos los derechos de propiedad intelectual e industrial referidos a los contenidos que en esta página web se incluyan, los cuales no pueden ser utilizados, de modo alguno, por terceros sin autorización de MEDICA MOVIL. Todo el material o contenido del Sitio Web, incluyendo, pero sin limitarse a los logos, imágenes, texto, ilustraciones, y archivos de audio y vídeo, están protegidos por derechos de autor, marcas u otros derechos de propiedad que pertenecen a MEDICA MOVIL. El material de este Sitio Web y de cualquier otro sitio web de propiedad, operado, controlado o licenciado por MEDICA MOVIL no puede ser copiado, reproducido, publicado, cargado, transmitido o distribuido de ninguna manera sin autorización expresa y por escrito de MEDICA MOVIL. Con excepción de lo expresamente previsto en los presentes, no se le concede ningún derecho o licencia de las patentes, derechos de autor, secretos comerciales, derechos de publicidad o marcas comerciales con respecto a cualquier contenido del Sitio Web, y MEDICA MOVIL se reserva todos los derechos no expresamente otorgados en el presente documento. <br/><br/>
    <strong>6. Garantía de Indemnidad.</strong> <br/>
        Cada Usuario se compromete a indemnizar y mantener indemne a MEDICA MOVIL, sus empresas controladas y/o controlantes, accionistas, directivos, representantes, apoderados y empleados de todas ellas, por cualquier reclamo, demanda, acción, penalidad, intereses, costos, multas u honorarios, iniciado por otros usuarios o terceros por sus actividades en el Sitio Web o por su incumplimiento a estos Términos y Condiciones de Uso y demás políticas que se entiendan parte de ellos, o por la violación de cualesquiera leyes o derechos de terceros. <br/><br/>
    <strong>7. Modificación.</strong> <br/>
        MEDICA MOVIL se reserva el derecho de modificar en forma unilateral, sin mediar previo aviso, ni comunicación los usuarios, estos Términos y Condiciones, como así el diseño, la presentación o configuración del Sitio Web, sin que ello genere derecho a reclamo o indemnización alguna en favor de los usuarios. En consecuencia, el usuario debe leer atentamente los Términos y Condiciones cada vez que pretenda acceder, navegar y utilizar el Sitio Web. <br/><br/>
    <strong>8. Limitación de responsabilidad.</strong> <br/>
        EL USUARIO RECONOCE Y ACEPTA QUE: <br/>
        A) EL USUARIO ASUME TODOS LOS RIESGOS RELATIVOS A, O DERIVADOS DE, EL USO, CONSULTA O ACCESO POR SU PARTE AL SITIO WEB. EL SITIO WEB SE FACILITA "COMO ESTÁ" Y "SEGÚN DISPONIBILIDAD", SIN GARANTÍA ALGUNA SOBRE SU CORRECTO Y CONTINUO FUNCIONAMIENTO. <br/>
        B) DENTRO DE LOS LÍMITES LEGALES, MEDICA MOVIL DECLINA EXPRESAMENTE Y EN ESTE ACTO, TODA GARANTÍA O RESPONSABILIDAD, EXPRESA O IMPLÍCITA, LEGAL O DE OTRO TIPO, INCLUIDAS LAS DERIVADAS DEL CONTENIDO DEL SITIO WEB. <br/>
        C) AUNQUE MEDICA MOVIL ADOPTA MEDIDAS DE SEGURIDAD PARA PROTEGER EL SITIO WEB Y SU CONTENIDO DE TODO TIPO DE ATAQUES INFORMÁTICOS CON EL FIN DE REDUCIR EL NIVEL DE RIESGO, EN ESTE ACTO MEDICA MOVIL DECLINA EXPRESAMENTE TODA GARANTÍA RESPECTO A: (I) QUE EL SITIO WEB Y SU CONTENIDO ESTARÁ SIEMPRE LIBRE DE ERRORES O VIRUS O NO SUFRIRÁ NUNCA ATAQUES DE TERCEROS; (II) EL FUNCIONAMIENTO ININTERRUMPIDO Y SIEMPRE SEGURO DEL SITIO WEB; (III) LA PERMANENTE DISPONIBILIDAD DEL SITIO WEB; (IV) QUE EL SITIO WEB CUBRA O NO LAS NECESIDADES DEL USUARIO; Y (V) LA FIABILIDAD, EXACTITUD, INTEGRIDAD, VALIDEZ O VERACIDAD DE LA INFORMACIÓN FACILITADA POR EL USUARIO. <br/>
        D) EL USUARIO ACEPTA ASUMIR TODOS LOS RIESGOS ASOCIADOS CON, O DERIVADOS DE, EL USO DEL SITIO WEB O LA INFORMACIÓN FACILITADA POR EL USUARIO. EL USUARIO SE COMPROMETE TAMBIÉN A EXONERAR DE TODA RESPONSABILIDAD A MEDICA MOVIL, A SUS SUBSIDIARIAS Y CONTROLANTES, ASÍ COMO A LOS DIRECTIVOS, ENCARGADOS, COMERCIALES Y EMPLEADOS DE TODAS ELLAS, FRENTE A LAS RECLAMACIONES, DEMANDAS O RECLAMACIONES DE INDEMNIZACIÓN POR DAÑOS Y PERJUICIOS (DIRECTOS, INDIRECTOS Y SOBREVENIDOS) DE CUALQUIER TIPO, CONOCIDAS O NO, QUE PUEDAN INTERPONERSE CON BASE EN, O QUE SE DERIVEN DEL USO DEL SITIO WEB, LA INFORMACIÓN FACILITADA POR EL USUARIO O LAS TRANSACCIONES QUE SE PRODUZCAN COMO RESULTADO DEL USO DEL SITIO WEB POR PARTE DEL USUARIO. <br/>
        SIEMPRE DENTRO DE LOS LÍMITES LEGALES, EL USUARIO ASUME Y ACEPTA QUE EN NINGÚN CASO, NI MEDICA MOVIL NI SUS SUBSIDIARIAS O CONTROLANTES, NI TAMPOCO SUS ACCIONISTAS, DIRECTIVOS, LOS COMERCIALES, LOS EMPLEADOS O LOS PROVEEDORES DE TODAS ELLAS, ASUMIRÁN RESPONSABILIDAD ALGUNA POR LOS DAÑOS EMERGENTES, DIRECTOS, INDIRECTOS O SOBREVENIDOS (INCLUIDOS, ENTRE OTROS, LOS DAÑOS A LA PROPIEDAD Y LOS DAÑOS PURAMENTE ECONÓMICOS), NI POR LUCRO CESANTE, PÉRDIDA DE CARTERA DE CLIENTES, PÉRDIDA DE INGRESOS O DE DATOS O POR INTERRUPCIÓN DE USO, SEA CUAL SEA LA CAUSA QUE LOS HAYA PROVOCADO - INCUMPLIMIENTO CONTRACTUAL, RESPONSABILIDAD EXTRACONTRACTUAL (INCLUIDA LA NEGLIGENCIA) O INCUMPLIMIENTO DE GARANTÍA-, INCLUSO AUNQUE MEDICA MOVIL HUBIERA SIDO ADVERTIDA DE LA POSIBILIDAD DE OCURRENCIA DE DICHAS PÉRDIDAS O DAÑOS. EN CASO DE QUE EN LA JURISDICCIÓN A LA QUE SE SOMETA UN EVENTUAL CONFLICTO CON EL USUARIO SE PROHIBA LA EXCLUSIÓN DE CIERTAS GARANTÍAS, LA LIMITACIÓN DE RESPONSABILIDAD O EL DESCARGO DE RESPONSABILIDAD RESPECTO A CIERTOS DAÑOS, LA RESPONSABILIDAD ACUMULADA DE MEDICA MOVIL POR DAÑOS NO EXCEDERÁ DE USD$10 (DIEZ DÓLARES ESTADOUNIDENSES), SI ASÍ LO PERMITE LA LEGISLACIÓN APLICABLE. <br/>
        EN CUALQUIER CASO, LA ANTERIOR LIMITACIÓN DE RESPONSABILIDAD NO SE APLICARÁ RESPECTO A LOS DAÑOS CAUSADOS POR DOLO. <br/><br/>
    <strong>9. General.</strong> <br/>
        Los presentes Términos y Condiciones, con las modificaciones que en el futuro se realicen al Sitio Web a exclusivo criterio de MEDICA MOVIL, constituyen el único acuerdo entre el Usuario y MEDICA MOVIL y rigen el uso que haga el usuario del Sitio Web, reemplazando cualquier contrato previo entre el Usuario y MEDICA MOVIL, ya sea verbal o escrito. Sin perjuicio de los términos y condiciones particulares que le puedan resultar aplicables al usuario por su registración en el Sitio Web. <br/>
        Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo a las leyes de los Estados Unidos Mexicanos excluyendo sus normas de derecho internacional privado. Cualquier conflicto que se suscite por causa o en relación a estos Términos y Condiciones será sometido y resuelto exclusivamente por los tribunales competentes de la Ciudad de México. <br/>
        La invalidez o inaplicabilidad de cualquiera de los presentes Términos y Condiciones -en todo o en parte- o de los derechos derivados de los mismos, no afectará la validez o la aplicabilidad de los demás derechos, términos y condiciones, o al resto de la disposición en cuestión, que continuarán en pleno vigor y efectos, quedando excluida solamente la cláusula viciada. <br/> <br/>
    <strong>10. Pagos.</strong> <br/>
        Las transacciones serán procesadas a través de la pasarela de pagos Openpay. <br/> <br/>

        División del Norte #2555, Col. Del Carmen. Alcaldia Coyoacán, C.P. 04100<br/>
        CDMX: (55)5482-3700<br/>
        GDL: (33) 3827-1191<br/>
        MTY: (81) 8356-9739<br/>
        Del resto de la República Mexicana 01800-9116-684<br/>
        O si lo prefieres, escríbenos a: atencionclientes&#64;medicamovil.com<br/>
       
    </p>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import momentTz from 'moment-timezone'
import moment from 'moment';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-re-boking',
    templateUrl: './re-boking.component.html',
    styleUrls: ['./re-boking.component.scss'],
    standalone: false
})
export class ReBokingComponent implements OnInit {

  doctor = {
    id:0,
    first_name:"",
    last_name:"",
    photo_media_id: undefined
  };
  doctorTimeSlots =  [];
  doctorTime = "";
  doctorDate = "";
  timeLabel = "Seleccione una hora";
  minDate = new Date();
  isHourSelected = false;

  constructor(
    public dialogRef: MatDialogRef<ReBokingComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private service: AuthService ) { }

  ngOnInit() {
    this.doctor = this.data.doctor;
    console.log(this.doctor)
  }

  getDate(date) {

    this.doctorDate = moment(date).format('MM/DD/YYYY');

    this.service.getDoctorTimeList(this.doctor.id, moment(date).valueOf()).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"public"));//private

      let slots = this.formatSlots(decData.data.items);

      let newSlots = []
      slots.forEach( slot =>{
        console.log(moment(slot.start).format('LLL'))
        if(moment(slot.start).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY')) {
          newSlots.push( { start: moment(slot.start).format('HH:mm'), slot_type:slot.slot_type } )
        }
      });

      // console.log(newSlots);
      this.doctorTimeSlots = newSlots;

      this.timeLabel = "Seleccione una hora";
      if (this.doctorTimeSlots.length == 0) {
        this.timeLabel = "No hay horas disponibles";
      }

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
    });

  }

  formatSlots(slots){
    let newTimesS = [];
    slots.forEach(element => {
      // get the day of the mont
      let date = moment.utc(element.day ).format('MMM/DD/YYYY');

      // get the times in the day of the mont
      let newTimes = element.availability.map(elem => {

        let start = moment(elem.start_at_miliseconds).format();
        let plushour = moment(elem.start_at_miliseconds).add(1,'hour').format();

        let end =  moment(plushour).format()

        return {
          start: new Date(start),
          minutes: elem.minutes,
          slot_type: elem.slot_type
        }
      })

      newTimesS = newTimesS.concat(newTimes)

    });
    return newTimesS
  }

  getFee() {
    this.isHourSelected = true;
  }

  newBooking(){
    let time = this.doctorTime+":00";
    let date = this.doctorDate + " " + time;
    // console.log(date)

    let start_at = momentTz(date).tz('UTC').format('x');
    console.log(start_at);

    let dateplus = moment(date).add(1,'hour');//.format('LLL');
    let end_at = momentTz(dateplus).tz('UTC').format('x');

    let data = {
      start_at,
      end_at,
    }

    console.log('FECHAS:',data);
    console.log('ID:',this.data.id)

    this.service.moveBooking(this.data.id, data).subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      Swal.fire({
        icon: 'success',
        title: 'Su cita ha sido reagendada con éxito',
        text: "Espere a que el terapeuta confirme",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.dialogRef.close();

    },err=>{
      let decData = JSON.parse(this.service.decrypt(err.error.message));
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: decData.error.errors[0].message,
      });
    });
  }

}

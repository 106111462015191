import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-reset-password-id',
    templateUrl: './reset-password-id.component.html',
    styleUrls: ['./reset-password-id.component.scss'],
    standalone: false
})
export class ResetPasswordIdComponent implements OnInit {

  newpass = "";
  token = "";

  constructor( private service: AuthService, private route:Router, private router: ActivatedRoute, ) { }

  ngOnInit() {
    this.token = this.router.snapshot.paramMap.get("id");
    console.log(this.token);
  }

  newPass() {

    let data = {
      password: this.newpass,
      token: this.token
    }

    console.log(data)

    this.service.rePassword(data).subscribe(d => {
      console.log(d);
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: "Su Contraseña ha sido cambiada",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.route.navigate(['landing']);

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: err.error.error.errors[0].message,
      });
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit {

  public registerFG: FormGroup;

  constructor( private _formBuilder: FormBuilder, private service: AuthService ) { }

  ngOnInit() {
    this.registerFG = this._formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  register(){
    console.log(this.registerFG.value);
    this.service.contactUs(this.registerFG.value);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: false
})
export class RegisterComponent implements OnInit {

  hide = true;
  maxDate;
  public registerFG: FormGroup;
  suscription ="";
  subscriptions = [];
  descriptions = '1 sesión';

  constructor(private _formBuilder: FormBuilder, private authService: AuthService,private route: ActivatedRoute) {
    this.suscription = (this.route.snapshot.paramMap.get("suscription"));
    if(isNaN(Number(this.suscription))){this.suscription = ""};
  }

  ngOnInit() {
    this.maxDate = moment().subtract('18','years').format();

    this.registerFG = this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      phone_number: ['', Validators.required],
      country: ['MX', Validators.required],
      date_of_birth: [''],
      gender: ['Male', Validators.required],
      suscription:[this.suscription]
    });

    this.authService.getPlans().subscribe((d: any) => {
      let decData = JSON.parse(this.authService.decrypt(d.message, "public"));
      console.log(decData.data.items);
      this.subscriptions = [];
      decData.data.items.forEach(item => {
        if(!item.isBusiness)
          this.subscriptions.push(item);
      });
    })
  }

  dateNow(date){
    console.log(date)
    this.registerFG.setValue({ ...this.registerFG.value, date_of_birth: date});

    console.log(this.registerFG.value)

  }

  register() {

    let date = new Date(this.registerFG.value.date_of_birth);

    let user = {
      "first_name": (this.registerFG.value.first_name),
      "last_name": (this.registerFG.value.last_name),
      "email": (this.registerFG.value.email),
      "password": (this.registerFG.value.password),
      "phone": (this.registerFG.value.phone_number),
      "country": (this.registerFG.value.country),
      "birth_at":  date.getTime(),
      "gender": (this.registerFG.value.gender),
      "plan_id": Number(this.registerFG.value.suscription)
    }

    // let encryptUSer = { data: this.authService.encrypt(user,"public") }

    this.authService.register(user);

  }


}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { SuscriptionModalComponent } from './suscription-modal/suscription-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ActivateLoadingAction, DeactivateLoadingAction } from 'src/app/redux/ui.actions';


@Component({
    selector: 'app-suscriptions',
    templateUrl: './suscriptions.component.html',
    styleUrls: ['./suscriptions.component.scss'],
    standalone: false
})
export class SuscriptionsComponent implements OnInit {

  subscriptions = [];
  planId = "";
  userPlan = { billing_cycle_end: "", cancelled_at: 0 };

  constructor(public service: AuthService, public dialog: MatDialog, private store: Store<AppState>) { }

  ngOnInit() {
    this.service.getPlans().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "public"));//private
      console.log(decData.data.items);
      this.subscriptions = [];
      decData.data.items.forEach(item => {
        if(!item.isBusiness)
          this.subscriptions.push(item);
      });

    })
    this.getCurrentSuscription();
  }

  getCurrentSuscription() {
    this.service.getCurrentSuscription().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items[0]);
      let data = decData.data.items[0];
      this.userPlan = data;
      this.planId = data.plan.conekta_id;
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
        });
      }
      let decData = JSON.parse(this.service.decrypt(err.error.message, ""));
      console.log(decData.error.errors[0])
      this.planId = "no_plan";
      if(this.service.user){
        if(this.service.user.account_status == "plan_unpaid"){
          this.height = '250px';
          this.addSubscription(this.service.user.plan_id);
        }
      }
    });
  }

  height = '200px';
  addSubscription(sub) {
    console.log(sub)

    const dialogRef = this.dialog.open(SuscriptionModalComponent, {
      width: '500px',
      height: this.height,
      data: { sub, title: `Al dar click en aceptar se realizara un cargo de $${sub.amount / 100} a su tarjeta.` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.store.dispatch(new ActivateLoadingAction());
        this.service.addSuscription(sub.id, sub).subscribe((d: any) => {

          let decData = JSON.parse(this.service.decrypt(d.message, "private"));
          this.store.dispatch(new DeactivateLoadingAction());
          Swal.fire({
            icon: 'success',
            title: 'Ya cuentas con tu plan!!',
            text: "Tu suscripción al plan puede verse reflejada hasta dentro de 2 horas",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
          // console.log(decData)
          this.getCurrentSuscription();

        }, err => {
          if(err.name === "HttpErrorResponse"){
            Swal.fire({
              title: 'Error de conexión',
              text: 'Intente de nuevo más tarde.',
              icon: 'error',
              showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
            });
          }
          this.store.dispatch(new DeactivateLoadingAction());
          let decData = JSON.parse(this.service.decrypt(err.error.message, "private"));
          console.log(decData)
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: decData.error.errors[0],
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
        });
      }

    });
  }

  freeSuscription() {

    const dialogRef = this.dialog.open(SuscriptionModalComponent, {
      width: '500px',
      height: this.height,
      data: { title: `Estas seguro de cancelar el plan? si lo cancela aun tendra sus terapias pendientes` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.service.deleteSuscription().subscribe((d: any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, "private"));
          Swal.fire({
            icon: 'success',
            title: 'Bien',
            text: "Ya cancelo su suscripción con éxito",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
          console.log(decData);
          if(this.service.user.account_status == "plan_unpaid"){
            this.service.user.account_status = "activated";
            this.service.user.plan_id = null;
          }
          this.getCurrentSuscription();
        }, err => {
          if(err.name === "HttpErrorResponse"){
            Swal.fire({
              title: 'Error de conexión',
              text: 'Intente de nuevo más tarde.',
              icon: 'error',
              showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
            });
          }
          let decData = JSON.parse(this.service.decrypt(err.error.message));
          console.log(decData)
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Ha ocurrido un error",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
            
          });
        });
      }
    });


  }


}

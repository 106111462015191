import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Categorie } from '../models/categorie.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivateLoadingAction, ChangeTitleNav } from '../redux/ui.actions';
import { UnsetCategorieDoctorsAction } from '../redux/categories-doctors.actions';
import moment from 'moment';
import 'moment/locale/es';

import { MatDialog } from '@angular/material/dialog';
import { FiltersComponent } from './filters/filters.component';
import { TimezoneComponent } from './timezone/timezone.component';
import { PdfModalComponent } from './pdf-modal/pdf-modal.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  catSuscription: Subscription = new Subscription();
  docSuscription: Subscription = new Subscription();
  uisubs: Subscription = new Subscription();

  doctors = [];
  categories: Categorie[] = [];
  cat_Id;
  firstModal = true;
  isLoading = false;
  seleccionao = false;

  constructor( private store: Store<AppState>, private authService: AuthService, private router: Router, public dialog: MatDialog ) {
    this.catSuscription = store.select('categories').subscribe(c => {
      if(this.authService.user.company_id == 8){
        c.data.forEach(cat => {
          if(cat.name.includes("NOM 035"))
            this.categories.push(cat);
        });
      }else
      console.log('CATEGORIES', c.data)
        this.categories = c.data;
      console.log(c.data)
      /**Timezone Validation */
      store.select("user").subscribe(user=>{
        console.log(user);
        if(!user.data.timezone_validated){
          this.authService.getInfoTimeZone(user.data.time_zone).subscribe({
            //next:(res)=>{this.openConfigTimeZone(res)},
            //error:()=>{}
          })
        }
      })
    });

    this.docSuscription = store.select('cdoctors').subscribe(cd =>{
      const a = [...cd.data];
      a.sort((a, b) => b.time_slots.length - a.time_slots.length);
      this.doctors = a;
      console.log(this.seleccionao)
      if(!this.seleccionao)this.toogleCard('cat1');
      console.log('DOCTORES:', this.doctors);
    })
   }

  ngOnInit() {
    this.uisubs = this.store.select('ui').subscribe( ui=>{
      this.isLoading = ui.isLoading;
    });

  }

  ngAfterViewInit(){
    if(this.authService.user.company_id == 8)
      this.goCategorie2(6,'cat6')
    else
      this.goCategorie2(1,'cat1')
  }

  goCategorie2(id,name){
    this.cat_Id = id;
    this.authService.getDoctors(id);
    this.toogleCard(name);
  }

  goCategorie(id, name){
    this.seleccionao = true;
    this.cat_Id = id;
    this.authService.getDoctors(id);
    this.toogleCard(name);
  }

  reset(){
    this.goCategorie(this.cat_Id,'cat'+this.cat_Id);
  }

  toogleCard(id){
    var sections = document.querySelectorAll('.img-container');
        for (let i = 0; i < sections.length; i++){
          sections[i].classList.remove('active-categori');
        }
    document.querySelector(`#${id}`).classList.add('active-categori');
    console.log('hola')
  }

  bookingNow(id){
    this.router.navigate(['panel/booking-now',id,this.cat_Id]);
  }

  ngOnDestroy(){
    this.doctors = [];
    this.catSuscription.unsubscribe();
    this.docSuscription.unsubscribe();
    this.uisubs.unsubscribe();
    this.store.dispatch( new ChangeTitleNav( "" ) );
    this.store.dispatch( new UnsetCategorieDoctorsAction())

  }

  formatDate(d){
    let date = new Date(d)
    return moment(date, "YYYYMMDD").fromNow(true);
  }

  // filters
  openFilters() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      width: '1000px',
      height: '780px',
      data: { data: 'filtros' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
         let startAt =(moment().startOf('month').format('YYYY-MM-DD'));
         let endAt = (moment().endOf('month').format('YYYY-MM-DD'));
         let start = `${result.horaInicio}:00:00`;
         let end = `${result.horaFin}:00:00`;
        
        let startDate = result.fechaInicio ? `${result.fechaInicio} ${start}` : `${startAt} ${start}`; 
        let endDate = result.fechaFin ? `${result.fechaFin} ${end}` : `${endAt} ${end}`; 
        let data = {
          startDate:startDate,
          endDate:endDate,
          searchTerm:result.nombre ? result.nombre: "",
          gender:result.genero ? result.genero: "",
          users:this.cat_Id
        }
        console.log(data)
        // return;
        this.store.dispatch(new ActivateLoadingAction());
        this.authService.getDoctorsFilter(data);
      }
    });
  }

  openConfigTimeZone(resultTimeZone){
    const dialogRef = this.dialog.open(TimezoneComponent,{
      width: '1000px',
      height: '780px',
      data: { data: resultTimeZone }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.editProfile({timezone_validated:true},this.authService.user.id).subscribe({
          next:()=>{
            alert("Se cerrara tu sesión para aplicar los cambios.")
            this.authService.logout()
          },
          error:()=>{}
        })
         /*let startAt =(moment().startOf('month').format('YYYY-MM-DD'));
         let endAt = (moment().endOf('month').format('YYYY-MM-DD'));
         let start = `${result.horaInicio}:00:00`;
         let end = `${result.horaFin}:00:00`;
        
        let startDate = result.fechaInicio ? `${result.fechaInicio} ${start}` : `${startAt} ${start}`; 
        let endDate = result.fechaFin ? `${result.fechaFin} ${end}` : `${endAt} ${end}`; 
        let data = {
          startDate:startDate,
          endDate:endDate,
          searchTerm:result.nombre ? result.nombre: "",
          gender:result.genero ? result.genero: "",
          users:this.cat_Id
        }
        console.log(data)
        // return;
        this.store.dispatch(new ActivateLoadingAction());
        this.authService.getDoctorsFilter(data);*/
      }
    });
  }

  setDefaultPic(event){
    event.target.src = './assets/icono.png';
  }

  openPdfModal(link:string): void {
    const dialogRef = this.dialog.open(PdfModalComponent, {
      width: '100%',
      height: '70%',
      position: {
        top:'10%'
      },
      data: {
        pdfLink: link, // Reemplaza con tu enlace web del PDF
      },
    });
  }
}
